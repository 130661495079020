<template>
  <div class="navbar-container d-flex content align-items-center nav-class px-2">
    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-block">
      <!--      <dark-Toggler class="d-none d-lg-block" />-->
      <img
        src="@/assets/images/logo/simi-connect-logo.png"
        class="logo-size-class"
      >
    </div>
    <!--    <notification-dropdown />-->
    <b-navbar-nav class="nav align-items-center ml-auto">
      <!--      <notification-dropdown />-->
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-md-flex user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{ currentUser.name }}
            </p>
            <span class="user-status">
              {{ (currentUser.user_has_role && currentUser.user_has_role.length > 0 && currentUser.user_has_role[0].role) ? currentUser.user_has_role[0].role.role : '' }}
            </span>
          </div>
          <b-avatar
            size="40"
            variant="light-primary"
            badge
            :src="currentUser.image?currentUser.image: require('@/assets/images/avatars/User-place-holder.svg')"
            class="badge-minimal"
            badge-variant="success"
          />
        </template>

        <!--        <b-dropdown-item link-class="d-flex align-items-center">-->
        <!--          <feather-icon-->
        <!--            size="16"-->
        <!--            icon="UserIcon"-->
        <!--            class="mr-50"-->
        <!--          />-->
        <!--          <span>Profile</span>-->
        <!--        </b-dropdown-item>-->
        <!--        <b-dropdown-divider />-->
        <b-dropdown-item
          v-if="!isUserAdmin && $route.name !== 'phone-verification'"
          :to="{ name: 'account-setting' }"
          link-class="d-flex align-items-center"
        >
          <feather-icon
            size="16"
            icon="SettingsIcon"
            class="mr-50"
          />
          <span>Settings</span>
        </b-dropdown-item>
        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="signOutLocal"
        >
          <feather-icon
            size="16"
            icon="LogOutIcon"
            class="mr-50"
          />
          <span>Logout</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BNavbarNav, BNavItemDropdown, BDropdownItem, BAvatar,
} from 'bootstrap-vue'

export default {
  components: {
    // BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BAvatar,
    // NotificationDropdown,
    // Navbar Components
    // DarkToggler,
  },

  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
    }
  },
  computed: {
    currentUser() {
      return this.$store.getters['user/getUser']
    },
    isUserAdmin() {
      return this.$store.getters['user/isUserAdmin']
    },
  },
  methods: {
    async signOutLocal() {
      await this.$store.dispatch('user/logoutUserWithRedirect')
    },
  },
}
</script>
<style lang="scss">
  .header-navbar.floating-nav {
 width:  calc(100vw - (100vw - 100%) - calc(2rem * 2)) !important;
    @media (max-width: 575.98px){
    margin-right: 2rem !important;
  }
}
</style>
